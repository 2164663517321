'use client';

import { useLingui } from '@lingui/react';
import type { LanguageCodeT } from '@prismo-io/schemas';
import { useParams, usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import type { Application } from '../types';
import { decode } from '../utils/decode';
export const useDecodePathnameWithQueryParams = (app: Application) => {
  const {
    i18n
  } = useLingui();
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();
  return useMemo(() => {
    return searchParams.toString() === '' ? decode({
      app,
      locale: i18n.locale as LanguageCodeT,
      pathname,
      params: params as Record<string, string>
    }) : `${decode({
      app,
      locale: i18n.locale as LanguageCodeT,
      pathname,
      params: params as Record<string, string>
    })}?${searchParams.toString()}`;
  }, [app, pathname, params, searchParams, i18n.locale]);
};