import { type ApiClient, NpsResults } from '@prismo-io/schemas';
import { z } from 'zod';

export const ParamsSchema = NpsResults.pick({
  nps_survey_id: true,
  responses: true,
});

export type ParamsSchemaT = z.infer<typeof ParamsSchema>;

const ResultSchema = z.boolean();

export type ResultSchemaT = z.infer<typeof ResultSchema>;

export const upsertNpsResult = async (
  client: ApiClient,
  params: ParamsSchemaT
): Promise<ResultSchemaT> => {
  const {
    data: { session },
  } = await client.auth.getSession();

  if (!session) {
    return ResultSchema.parse(false);
  }

  const userId = session.user.id;
  const { nps_survey_id, responses } = ParamsSchema.parse(params);

  await client
    .from('nps_results')
    .upsert({
      nps_survey_id: nps_survey_id,
      user_id: userId,
      responses: responses,
    })
    .throwOnError();

  return ResultSchema.parse(true);
};
