import { z } from 'zod';
import { NpsSurveyContentType } from '../enums';

export const NpsSurveyResponseGauge = z.object({
  kind: z.literal(NpsSurveyContentType.Enum.GAUGE),
  response: z.number().nullable(),
});

export type NpsSurveyResponseGaugeT = z.infer<typeof NpsSurveyResponseGauge>;

export const NpsSurveyResponseTextarea = z.object({
  kind: z.literal(NpsSurveyContentType.Enum.TEXTAREA),
  response: z.string().nullable(),
});

export type NpsSurveyResponseTextareaT = z.infer<
  typeof NpsSurveyResponseTextarea
>;

export const NpsResults = z.object({
  id: z.string().uuid(),
  nps_survey_id: z.string().uuid(),
  user_id: z.string().uuid(),
  responses: z
    .discriminatedUnion('kind', [
      NpsSurveyResponseGauge,
      NpsSurveyResponseTextarea,
    ])
    .array()
    .min(1),
  created_at: z.date({ coerce: true }),
  updated_at: z.date({ coerce: true }),
});

export type NpsResultsT = z.infer<typeof NpsResults>;

export const NpsResultsInsert = NpsResults.partial().merge(
  NpsResults.pick({
    nps_survey_id: true,
    user_id: true,
    responses: true,
  })
);

export type NpsResultsInsertT = z.infer<typeof NpsResultsInsert>;

export const NpsResultsUpdate = NpsResults.partial();

export type NpsResultsUpdateT = z.infer<typeof NpsResultsUpdate>;
