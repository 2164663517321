'use client';

import { useApi } from '@prismo-io/core';
import { type Application, useDecodePathnameWithQueryParams } from '@prismo-io/router';
import { AppType, type AppTypeT, NpsSurveyType, NpsSurveys, type NpsSurveysT } from '@prismo-io/schemas';
import { useUsermaven } from '@prismo-io/tracking';
import { type FC, type PropsWithChildren, createContext, useContext, useState } from 'react';
import useSWR from 'swr';
import { NpsFormStepper } from '../nps-form-stepper';
import { RelationshipBanner } from '../relationship-banner';
import { TransactionalModal } from '../transactional-modal';
type Props = {
  app?: AppTypeT;
};
const Ctx = createContext<NpsSurveysT | undefined>(undefined);
export const NpsListener = () => {
  const survey = useContext(Ctx);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  if (!survey) {
    return null;
  }
  if (survey.type === NpsSurveyType.Enum.TRANSACTIONAL) {
    return <TransactionalModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <NpsFormStepper survey={survey} onComplete={() => setIsOpen(false)} />
      </TransactionalModal>;
  }
  if (survey.type === NpsSurveyType.Enum.RELATIONSHIP) {
    return <RelationshipBanner isVisible={isOpen}>
        <NpsFormStepper survey={survey} onComplete={() => setIsOpen(false)} />
      </RelationshipBanner>;
  }
  return <></>;
};
export const NpsSurveyProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    app = AppType.Enum.ONBOARDING
  } = props;
  const {
    client
  } = useApi();
  const decodedPathname = useDecodePathnameWithQueryParams(app as Application);
  const usermaven = useUsermaven();
  const {
    data: npsSurvey
  } = useSWR(() => decodedPathname ? ['nps', 'survey', decodedPathname] : null, async () => {
    const {
      data: {
        session
      }
    } = await client.auth.getSession();
    if (!session) {
      return null;
    }
    const now = new Date().toISOString();
    const pathname = `/:locale${decodedPathname}`;
    const {
      data: survey,
      error
    } = await client.from('nps_surveys').select('*, nps_results(id)').lte('start_at', now).eq('application', app).eq('url', pathname).eq('nps_results.user_id', session.user.id).or(`end_at.is.null,end_at.gte.${now}`).limit(1).maybeSingle();
    if (error || !survey || survey.nps_results.length !== 0) {
      return null;
    }
    const {
      content,
      ...payloadSurvey
    } = survey;
    usermaven.track('nps-show', {
      ...payloadSurvey,
      user_id: session.user.id
    });
    return NpsSurveys.parse(survey);
  }, {
    revalidateOnMount: true,
    revalidateOnFocus: false
  });
  return <Ctx.Provider value={npsSurvey ?? undefined} data-sentry-element="unknown" data-sentry-component="NpsSurveyProvider" data-sentry-source-file="index.tsx">{children}</Ctx.Provider>;
};