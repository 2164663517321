'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { Button, Stack, Typography } from '@mui/material';
import { TextareaInput } from '@prismo-io/design-system';
import { type FC, useCallback } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
type Props = {
  question: string;
  pass?: string;
  valid?: string;
  placeholder?: string;
  onComplete: (response: string | null | undefined) => void | Promise<void>;
};
const ValidatorSchema = z.object({
  response: z.string().optional()
});
type ValidatorSchemaT = z.infer<typeof ValidatorSchema>;
export const NpsTextarea: FC<Props> = props => {
  const {
    question,
    onComplete,
    pass = undefined,
    valid = t`Valider`,
    placeholder = t`Description`
  } = props;
  const {
    control,
    handleSubmit,
    formState: {
      isValid
    }
  } = useForm<ValidatorSchemaT>({
    resolver: zodResolver(ValidatorSchema)
  });
  const onSubmit: SubmitHandler<ValidatorSchemaT> = useCallback(data => {
    onComplete(data.response);
  }, [onComplete]);
  const onPass = () => {
    onComplete('');
  };
  return <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={2} padding={1} data-sentry-element="Stack" data-sentry-component="NpsTextarea" data-sentry-source-file="nps-textarea.tsx">
      <Typography fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="nps-textarea.tsx">{question}</Typography>
      <TextareaInput control={control} name="response" label={placeholder} data-sentry-element="TextareaInput" data-sentry-source-file="nps-textarea.tsx" />
      <Stack direction="row" justifyContent={!pass ? 'flex-end' : 'space-between'} alignItems="center" data-sentry-element="Stack" data-sentry-source-file="nps-textarea.tsx">
        {!!pass && <Button type="button" onClick={onPass} variant="outlined">
            {pass}
          </Button>}
        <Button type="submit" variant="contained"
      // disabled={!isValid}
      data-sentry-element="Button" data-sentry-source-file="nps-textarea.tsx">
          {valid}
        </Button>
      </Stack>
    </Stack>;
};