import { datadogRum } from '@datadog/browser-rum';
import { env } from '@prismo-io/env';
import {
  type EventPayload,
  type UserProps,
  usermavenClient,
} from '@usermaven/sdk-js';
import { UsermavenClientMocked } from './mock';
import type { Usermaven } from './types';

type Config = {
  usermavenKey?: string;
  datadogKey?: string;
  log?: boolean;
};

const createUsermaven = (apiKey: string = env.NEXT_PUBLIC_USERMAVEN_KEY) => {
  if (apiKey && apiKey.trim() !== '') {
    return usermavenClient({
      key: apiKey,
      tracking_host: 'https://events.usermaven.com',
      autocapture: typeof document !== 'undefined',
      privacy_policy: 'strict',
      randomize_url: false,
    });
  }

  return UsermavenClientMocked;
};

const createDatadog = (
  applicationId: string | undefined = env.NEXT_PUBLIC_DATADOG_APPLICATION_ID
) => {
  if (applicationId && applicationId.trim() !== '') {
    return {
      id: async (userData: UserProps, doNotSendEvent = false) => {
        datadogRum.setUser({
          id: userData.id,
          email: userData.email,
        });
      },
      track: async (typeName: string, payload?: EventPayload) => {
        if (typeName === 'pageview') {
          return;
        }

        datadogRum.addAction(typeName, payload);
      },
    };
  }
  return {
    id: async (userData: UserProps) => {},
    track: async (typeName: string, payload?: EventPayload) => {},
  };
};

export const Wrapper = (opts?: Config): Usermaven => {
  const { usermavenKey, datadogKey, log = false } = opts || {};

  const usermaven = createUsermaven(usermavenKey);
  const datadog = createDatadog(datadogKey);

  return {
    id: async (userData: UserProps, doNotSendEvent = false) => {
      if (log) {
        console.groupCollapsed('Tracking ID');
        console.log('UserData =>', userData);
        console.log('doNotSendEvent =>', doNotSendEvent);
        console.groupEnd();
      }

      usermaven.id(userData, doNotSendEvent);
      datadog.id(userData);
    },
    track: async (typeName: string, payload?: EventPayload) => {
      if (log) {
        console.groupCollapsed('Usermaven Track =>', typeName);
        console.log('typeName =>', typeName);
        console.log('payload =>', payload);
        console.groupEnd();
      }

      usermaven.track(typeName, payload);
      datadog.track(typeName, payload);
    },
  };
};
