'use client';

import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import type { FC, ReactNode } from 'react';
type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};
export const TransactionalModal: FC<Props> = ({
  children,
  onClose,
  isOpen = true
}) => {
  return <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" open={isOpen} onClose={onClose} closeAfterTransition slots={{
    backdrop: Backdrop
  }} slotProps={{
    backdrop: {
      timeout: 500
    }
  }} data-sentry-element="Modal" data-sentry-component="TransactionalModal" data-sentry-source-file="index.tsx">
      <Fade in={isOpen} data-sentry-element="Fade" data-sentry-source-file="index.tsx">
        <Box sx={{
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
          none: '100%',
          md: 'fit-content'
        },
        maxWidth: '90vw',
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '4px',
        p: 4
      }} component="article" data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Box component="div" position="relative" data-sentry-element="Box" data-sentry-source-file="index.tsx">
            <Box component="div" position="absolute" top={0} right={0} zIndex={10} sx={{
            transform: 'translate(50%, -50%)'
          }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
              <IconButton onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
                <CloseIcon data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
              </IconButton>
            </Box>
            {children}
          </Box>
        </Box>
      </Fade>
    </Modal>;
};