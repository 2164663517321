'use client';

import { usermavenClient } from '@usermaven/sdk-js';
import { usePathname, useSearchParams } from 'next/navigation';
import { type FC, type PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { UsermavenCtx } from './context';
import { createTrackingClient } from './create-tracking-client';
import { UsermavenClientMocked } from './mock';
import type { Usermaven } from './types';
type Props = {
  apikey?: string;
  withPageTracking?: boolean;
};
export const UsermavenProvider: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    apikey,
    withPageTracking = false
  } = props;
  const hasKey = useMemo(() => typeof apikey === 'string' && apikey.trim() !== '', [apikey]);
  const [client, setClient] = useState<Usermaven | undefined>(undefined);
  useEffect(() => {
    const instance = createTrackingClient(apikey);
    setClient(instance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasKey, apikey]);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const url = useMemo(() => {
    if (searchParams.size === 0) {
      return pathname;
    }
    return `${pathname}?${searchParams.toString()}`;
  }, [pathname, searchParams]);
  useEffect(() => {
    // Track page views
    if (withPageTracking && client !== undefined) {
      client.track('pageview');
    }
  }, [url, client, withPageTracking]);
  if (!client) {
    return <>{children}</>;
  }
  return <UsermavenCtx.Provider value={{
    client: client,
    isReady: true
  }} data-sentry-element="unknown" data-sentry-component="UsermavenProvider" data-sentry-source-file="provider.tsx">
      {children}
    </UsermavenCtx.Provider>;
};