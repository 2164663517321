import { z } from 'zod';

export const AppType = z.enum([
  'APP',
  'TOOLS',
  'ONBOARDING',
  'AURA',
  'HELIO',
  'QUARK',
]);

export type AppTypeT = z.infer<typeof AppType>;
