'use client';

import { createContext, useContext } from 'react';
import type { UsermavenContext } from './types';

export const UsermavenCtx = createContext<UsermavenContext>({
  client: undefined,
  isReady: false,
});

export const useUsermavenContext = () => useContext(UsermavenCtx);
