import { z } from 'zod';
import { AppType, NpsSurveyContentType, NpsSurveyType } from '../enums';

export const NpsSurveyContentTextarea = z.object({
  kind: z.literal(NpsSurveyContentType.Enum.TEXTAREA),
  sentence: z.object({
    fr: z.string(),
    en: z.string(),
  }),
  placeholder: z
    .object({
      fr: z.string(),
      en: z.string(),
    })
    .nullish(),
});

export type NpsSurveyContentTextareaT = z.infer<
  typeof NpsSurveyContentTextarea
>;

export const NpsSurveyContentGauge = z.object({
  kind: z.literal(NpsSurveyContentType.Enum.GAUGE),
  sentence: z.object({
    fr: z.string(),
    en: z.string(),
  }),
  bad: z
    .object({
      fr: z.string(),
      en: z.string(),
    })
    .nullish(),
  good: z
    .object({
      fr: z.string(),
      en: z.string(),
    })
    .nullish(),
});

export type NpsSurveyContentGaugeT = z.infer<typeof NpsSurveyContentGauge>;

export const NpsSurveys = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  content: z
    .discriminatedUnion('kind', [
      NpsSurveyContentTextarea,
      NpsSurveyContentGauge,
    ])
    .array()
    .min(1),
  url: z.string(),
  application: AppType,
  type: NpsSurveyType,
  start_at: z.date({ coerce: true }),
  end_at: z.date({ coerce: true }).nullable(),
  created_at: z.date({ coerce: true }),
  updated_at: z.date({ coerce: true }),
});

export type NpsSurveysT = z.infer<typeof NpsSurveys>;

export const NpsSurveysInsert = NpsSurveys.partial().merge(
  NpsSurveys.pick({
    name: true,
    description: true,
    content: true,
    url: true,
    application: true,
    type: true,
  })
);

export type NpsSurveysInsertT = z.infer<typeof NpsSurveysInsert>;

export const NpsSurveysUpdate = NpsSurveys.partial();

export type NpsSurveysUpdateT = z.infer<typeof NpsSurveysUpdate>;
