import { useApi } from '@prismo-io/core';
import type { SWRArgs } from '@prismo-io/schemas';
import useSWRMutation from 'swr/mutation';
import { type ParamsSchemaT, upsertNpsResult } from '../api/upsert-nps-result';
export function useUpsertNpsResult() {
  const {
    client
  } = useApi();
  return useSWRMutation('upsert-nps-result', (key, {
    arg
  }: SWRArgs<ParamsSchemaT>) => {
    return upsertNpsResult(client, arg);
  });
}