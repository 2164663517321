'use client';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Collapse, IconButton, Paper } from '@mui/material';
import { type FC, type PropsWithChildren, useState } from 'react';
type Props = {
  isVisible?: boolean;
};
export const RelationshipBanner: FC<PropsWithChildren<Props>> = props => {
  const {
    children,
    isVisible = false
  } = props;
  const [open, setOpen] = useState<boolean>(true);
  if (!isVisible) return null;
  return <Box component="div" position="fixed" bottom="1rem" right="1rem" zIndex={10000} data-sentry-element="Box" data-sentry-component="RelationshipBanner" data-sentry-source-file="index.tsx">
      <Collapse in={open} data-sentry-element="Collapse" data-sentry-source-file="index.tsx">
        <Paper variant="elevation" sx={{
        position: 'relative',
        padding: 1
      }} data-sentry-element="Paper" data-sentry-source-file="index.tsx">
          <IconButton aria-label="close" color="inherit" size="small" sx={{
          position: 'absolute',
          top: 1,
          right: 1
        }} onClick={() => {
          setOpen(false);
        }} data-sentry-element="IconButton" data-sentry-source-file="index.tsx">
            <CloseIcon fontSize="inherit" data-sentry-element="CloseIcon" data-sentry-source-file="index.tsx" />
          </IconButton>
          {children}
        </Paper>
      </Collapse>
    </Box>;
};