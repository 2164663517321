'use client';

import 'client-only';

export const cookies = (): Record<string, string> => {
  if (typeof document === 'undefined') {
    return {} as Record<string, string>;
  }

  if (document?.cookie) {
    return document.cookie.split(';').reduce(
      (ac: Record<string, string>, cv: string) => {
        const [key, value] = cv.split('=') as [string, string];

        return {
          ...ac,
          [key]: value,
        };
      },
      {} as Record<string, string>
    );
  }

  return {} as Record<string, string>;
};
